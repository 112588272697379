

















import { Component, Vue, Prop } from 'vue-property-decorator'

@Component({
  components: {},
})
export default class Checkbox extends Vue {
  @Prop({}) value!: string
}
